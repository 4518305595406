.project-image {
  display: block;
  max-width: 800px;
  margin: 0 auto;
  border: 2px solid #2d3439;
  border-radius: 10px;
}

@media (max-width: 860px) {
  .project-window {
    height: 400rem;
    overflow: scroll;
  }
  .project-image {
    object-fit: cover;
    width: 100%;
  }
}
