#profile {
    margin-left: 100px;
    max-width: 1200px;
}

#headshot {
    float: left;
    max-width: 300px;
    border-radius: 50%;
    padding: 0px 20px 10px 0px;
}

@media (max-width: 768px) {
    #profile {
        border: 1px solid #FFFF;
        border-radius: 10px;
        background-image: linear-gradient(to bottom, #526888, #EEDAB5);
        margin: 0 auto 60px auto;
        padding-top: 20px;
    }
    .bio {
        padding: 15px;
    }
    #contact {
        font-size: 10px;
        float: clear;
        margin-right: 0;
    }
    #headshot {
        zoom: 1.5;
        border-radius: 20px;
        max-width: 100%;
        padding: 10px;
    }
}