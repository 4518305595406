.portfolio {
    background-color: #AAC0D5;
    max-width: 1200px;
    border: 3px solid #2D3439;
    border-radius: 20px;
    margin-bottom: 60px;
}

.portfolio h1 {
    text-align: center;
    padding: 10px;
}

