
#contact {
    float: right;
    margin-right: 150px
}

.contact a {
    color: #8a2000;
    font-size: 24px;
    padding: 10px 20px;
}

.contact span {
    font-family: "Special Elite", "Helvetica";
}

@media (max-width: 630px) {
    #contact {
        float: clear;
        margin-right: 0;
    }
}