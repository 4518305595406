@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono&family=Pinyon+Script&family=Special+Elite&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: url(/static/media/main-image.dd0cccf3.jpg) no-repeat center center fixed;
  background-size: cover;
}

header,
footer {
  background-color: #2d3439;
  color: #ff6333;
}

.container {
  height: 100vh;
  width: 100vw;
}

h1 {
  color: #8a2000;
  font-size: 24px;
  font-family: "Special Elite", "Helvetica";
}

h2 {
  color: #8a2000;
  font-family: "Pinyon Script", "Helvetica";
  font-size: 36px;
  margin-top: 15px;
}

p {
  color: #8a2000;
  font-size: 16px;
  font-family: "JetBrains Mono";
}

a {
  color: #ff6333;
  font-family: "Special Elite", "Helvetica";
  font-size: 18px;
}

a:hover {
  color: #ffffff;
}

.fa {
  font-size: 20px;
}

.navbar-toggler {
  color: #ff6333;
}

.sig {
  padding-left: 50px;
}

.row {
  /* background-color: #E6837D;
    border: 20px solid #FF6333;
    border-radius: 10px; */
  padding: 10px;
}

.col {
  /* background-color: #FF71A7;
    border: 2px solid#FF3C86; */
  color: #61666c;
}

#profile {
  margin-left: 100px;
  max-width: 1200px;
}

#headshot {
  float: left;
  max-width: 300px;
  border-radius: 50%;
  padding: 0px 20px 10px 0px;
}

.portfolio {
  background-color: #aac0d5;
  max-width: 1200px;
  border: 3px solid #2d3439;
  border-radius: 20px;
}

.portfolio h1 {
  text-align: center;
  padding: 10px;
}

.d-block {
  max-width: 800px;
  margin: 0 auto;
  border: 2px solid #2d3439;
  border-radius: 10px;
}

#contact {
  float: right;
  margin-right: 150px;
}

.contact a {
  color: #8a2000;
  font-size: 24px;
  padding: 10px 20px;
}

.contact span {
  font-family: "Special Elite", "Helvetica";
}

.footer .fa {
  letter-spacing: 5px;
}

@media (max-width: 630px) {
  #profile {
    border: 1px solid #ffff;
    border-radius: 10px;
    background-image: linear-gradient(to bottom, #526888, #eedab5);
    margin: 0 auto 60px auto;
    padding-top: 20px;
  }
  .bio {
    padding: 15px;
  }
  #profile {
    margin-left: 0;
  }
  .arrow {
    position: absolute;
    bottom: 40px;
  }

  #headshot {
    zoom: 1.5;
    border-radius: 20px;
    max-width: 100%;
    padding: 10px;
  }
}

@-webkit-keyframes pulse {
  0% {
    color: #2d3439;
  }
  100% {
    color: #ff6333;
  }
}

@keyframes pulse {
  0% {
    color: #2d3439;
  }
  100% {
    color: #ff6333;
  }
}


#contact {
    float: right;
    margin-right: 150px
}

.contact a {
    color: #8a2000;
    font-size: 24px;
    padding: 10px 20px;
}

.contact span {
    font-family: "Special Elite", "Helvetica";
}

@media (max-width: 630px) {
    #contact {
        float: clear;
        margin-right: 0;
    }
}
.icon-link {
    padding: 2px;
}
#profile {
    margin-left: 100px;
    max-width: 1200px;
}

#headshot {
    float: left;
    max-width: 300px;
    border-radius: 50%;
    padding: 0px 20px 10px 0px;
}

@media (max-width: 768px) {
    #profile {
        border: 1px solid #FFFF;
        border-radius: 10px;
        background-image: linear-gradient(to bottom, #526888, #EEDAB5);
        margin: 0 auto 60px auto;
        padding-top: 20px;
    }
    .bio {
        padding: 15px;
    }
    #contact {
        font-size: 10px;
        float: clear;
        margin-right: 0;
    }
    #headshot {
        zoom: 1.5;
        border-radius: 20px;
        max-width: 100%;
        padding: 10px;
    }
}
.navbar-brand {
  color: #ff6333;
  -webkit-animation: pulse 4s linear;
          animation: pulse 4s linear;
  font-size: 28px;
  font-family: "Special Elite", "Helvetica";
}

header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}

.portfolio {
    background-color: #AAC0D5;
    max-width: 1200px;
    border: 3px solid #2D3439;
    border-radius: 20px;
    margin-bottom: 60px;
}

.portfolio h1 {
    text-align: center;
    padding: 10px;
}


.project-image {
  display: block;
  max-width: 800px;
  margin: 0 auto;
  border: 2px solid #2d3439;
  border-radius: 10px;
}

@media (max-width: 860px) {
  .project-window {
    height: 400rem;
    overflow: scroll;
  }
  .project-image {
    object-fit: cover;
    width: 100%;
  }
}

